<!--
* @description ticad管理平台
* @fileName eicad.vue
* @author liulian
* @date 2023/06/29 16:33:22
-->
<template>
  <div class="ticad">
    <div class="z_banner">
      <img
        class="z_b_img"
        :src="$t(`ticad.banner`)"
        alt="banner"
      />
    </div>
    <div class="ptjs">
      <div class="p_con">
        <div class="z_title text-align-center font-size-36 font-weight-600">{{ $t("ticad.section1_title") }}</div>
        <div
          class="p_descript font-size-18 font-weight-400"
          style="color: rgba(29, 29, 31, 0.6)" >
          {{ $t("ticad.section1_content1") }}
          <br />
        </div>
        <div class="imgs_box flex-row align-center">
          <div class="i_b_left">
            <img
              class="z_b_l_img"
              src="@/assets/images/productImg/cpjs_1@2x.png"
              alt="cpjs_1@2x.png"
            />
          </div>
          <div class="i_b_right flex-column">
            <img
              class="z_b_r_img"
              src="@/assets/images/productImg/cpjs_2@2x.png"
              alt="cpjs_2@2x.png"
            />
            <img
              class="z_b_r_img"
              src="@/assets/images/productImg/cpjs_3@2x.png"
              alt="cpjs_3@2x.png"
            />
          </div>
        </div>
        <div class="p_p text-align-center font-size-18 font-weight-500">
          {{ $t("ticad.section1_content2") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ticad",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.ticad {
  padding-bottom: 171px;
  background-color: #fff;
  .z_title {
    color: rgba(29, 29, 31, 0.9);
  }
  .z_b_img {
    width: 100%;
    height: 400px;
  }
  .ptjs {
    // height: 1010px;
    margin-top: -3px;
    background-color: #fff;

    .p_con {
      width: 1200px;
      padding-top: 48px;
      margin: auto;

      .p_descript {
        margin-top: 18px;
        font-size: 18px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 1px;
        margin-bottom: 32px;
        text-indent: 36px;
        color: rgba(29, 29, 31, 0.8);
      }

      .imgs_box {
        height: 446px;
        .z_b_l_img {
          width: 779px;
          height: 466px;
        }
        .z_b_r_img:first-child {
          width: 420px;
          height: 283px;
          margin-top: -4px;
        }
        .z_b_r_img:last-child {
          width: 420px;
          height: 186px;
          margin-top: -4px;
        }
      }
      .p_p {
        color: rgba(29, 29, 31, 0.8);
        line-height: 32px;
        letter-spacing: 1px;
        margin-top: 32px;
      }
    }
  }
}
</style>
